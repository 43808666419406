<template>
    <div class="page-body">
        <div class="table-body">
            <div class="table-header">
                <div class="handle-box">
                    <table style="width: 100%">
                        <tr>
                            <td class="td-text">
                                合同编号：
                            </td>
                            <td class="td-input">
                                <el-input v-model="searchItem.contractNum" size="small" placeholder="请输入合同申请编号"
                                ></el-input>
                            </td>
                            <td class="td-text">
                                公司名称：
                            </td>
                            <td class="td-input">
                                <el-input v-model="searchItem.companyName" size="small" placeholder="请输入公司名称"
                                ></el-input>
                            </td>
                            <td class="td-text">
                                销售人员：
                            </td>
                            <td class="td-input">
                                <el-select v-model="searchItem.userName" size="small"
                                           style="width: 100%"
                                           filterable
                                           placeholder="请选择销售人员">
                                    <el-option
                                            v-for="item in saleList"
                                            :key="item"
                                            :label="item"
                                            :value="item">
                                    </el-option>
                                </el-select>
                            </td>
                        </tr>
                        <tr>
                            <td class="td-text" style="padding-top: 10px;">
                                联系电话：
                            </td>
                            <td class="td-input" style="padding-top: 10px">
                                <el-input v-model="searchItem.userTel" size="small" placeholder="请输入联系电话"
                                ></el-input>
                            </td>
                            <td class="td-text" style="padding-top: 10px">
                                合同类型：
                            </td>
                            <td class="td-input">
                                <template>
                                    <el-select v-model="searchItem.contractType" style="margin-top: 10px;width: 100%"
                                               size="small"

                                               placeholder="请选择合同类型">
                                        <el-option
                                                v-for="item in contractTypes"
                                                :key="item.value"
                                                :label="item.label"
                                                :value="item.value">
                                        </el-option>
                                    </el-select>
                                </template>
                            </td>
                            <td class="td-text" style="padding-top: 10px">
                                公司类型：
                            </td>
                            <td>
                                <template>
                                    <el-select v-model="searchItem.companyType" style="padding-top: 10px;width: 100%"
                                               size="small"
                                               placeholder="请选择公司类型">
                                        <el-option
                                                v-for="item in unitTypes"
                                                :key="item.value"
                                                :label="item.label"
                                                :value="item.value">
                                        </el-option>
                                    </el-select>
                                </template>
                            </td>
                        </tr>
                        <tr>
                            <td class="td-text" style="padding-top: 10px">
                                审批时间：
                            </td>
                            <td class="td-input" style="padding-top: 10px">
                                <el-date-picker
                                        v-model="searchItem.startDate"
                                        type="date"
                                        size="small"
                                        placeholder="选择日期时间"
                                        style="width: 46%;"
                                        class="handle-input-date"
                                        value-format="yyyy-MM-dd">
                                </el-date-picker>
                                至
                                <el-date-picker
                                        v-model="searchItem.endDate"
                                        type="date" placeholder="选择日期时间"
                                        size="small"
                                        style="width: 46%;"
                                        class="handle-input-date"
                                        value-format="yyyy-MM-dd">
                                </el-date-picker>
                            </td>
                            <td></td>
                            <td></td>
                            <td style="padding-top: 10px;text-align: center" colspan="2">
                                <el-button type="primary" icon="el-icon-search" size="small"
                                           @click="searchBtn">搜索
                                </el-button>
                                <el-button type="primary" icon="el-icon-refresh-right" style="margin-left: 25%"
                                           size="small" @click="resetBtn">重置
                                </el-button>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="table-info">
                <div class="table-button">
                    <div>
                        <el-table
                                :data="tableData"
                                :max-height="tableHeight"
                                style="width: 100%;height: 100%;"
                                :border="true"
                                :header-cell-style="{ background: '#EBEEF8',height: '45px', 'text-align':'center' }"
                                :cell-style="{padding:'0',height: lineHeight}">
                            <el-table-column type="index" label="序号" width="50" align="center"></el-table-column>
                            <el-table-column prop="contractNum" label="合同编号" width="180"
                                             align="center"></el-table-column>
                            <el-table-column prop="contractType" label="合同类型" width="120" align="center">
                                <template slot-scope="scope">
                                    <span v-if="scope.row.contractType == 0">销售合同</span>
                                    <span v-else-if="scope.row.contractType == 1">技术服务合同</span>
                                    <span v-else-if="scope.row.contractType == 2">部件买卖合同</span>
                                    <span v-else-if="scope.row.contractType == 3">售后合同</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="userName" label="销售人员" width="140" align="center"></el-table-column>
                            <el-table-column prop="userTel" label="联系电话" width="110" align="center"></el-table-column>
                            <el-table-column label="应收账款(元)" width="140" align="right" show-overflow-tooltip>
                                <template slot-scope="scope">
                                    <span v-if="scope.row.contractReceivables != 0">{{(scope.row.contractReceivables/100).toFixed(2)}}</span>
                                    <span v-if="scope.row.contractReceivables == 0">0</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="companyName" label="客户名称" width="280"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="createTime" label="审批时间" width="110" :formatter="dateFormat"
                                             align="center" show-overflow-tooltip></el-table-column>
                            <el-table-column prop="companyType" label="公司类型" width="100" align="center">
                                <template slot-scope="scope">
                                    <span v-if="scope.row.companyType == 0">建机厂</span>
                                    <span v-else-if="scope.row.companyType == 1">国企</span>
                                    <span v-else-if="scope.row.companyType == 2">租赁商</span>
                                    <span v-else-if="scope.row.companyType == 3">代理商</span>
                                    <span v-else-if="scope.row.companyType == 4">集团公司</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="createUserName" label="审批人" width="90"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="auditState" label="当前状态" width="100" align="center">
                                <template slot-scope="scope">
                                    <span v-if="scope.row.isPass == 0" style="color: red">驳回</span>
                                    <span v-else style="color: green">同意</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="auditRemark" label="审批意见" align="center"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="id" label="操作" width="50" align="center">
                                <template slot-scope="scope">
                                    <div class="opertion">
                                        <i class="iconfont iconxiangqing" title="查看详情"
                                           @click="showContract(scope.row.id)"></i>
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
                <div class="table-data">
                    <Pagination :pageSize="option.pageSize" :pageTotal="totalPage" :pageCurrent.sync="option.pageIndex"
                                :pageList="pageList" @handlePageChange="pageChange" @handleSizeChange="pageSizeChange"/>
                </div>
            </div>
        </div>
        <div class="information">
            <el-dialog
                    :title="showTitle"
                    :visible.sync="infoVisible"
                    width="70%"
                    style="margin-top: -90px"
                    :close-on-click-modal="false"
                    center>
                <div class="dialog-body">
                    <div class="basic-info">
                        <div class="text-title">
                            基础信息
                        </div>
                        <div>
                            <table style="text-align: left;width: 100%">
                                <tr>
                                    <td style="padding: 10px;width: 120px">
                                        客户名称：
                                    </td>
                                    <td style="width: 300px;">
                                        {{contractForm.companyName}}
                                    </td>
                                    <td style="padding: 10px;width: 120px">合同号：</td>
                                    <td style="width: 170px">
                                        {{contractForm.contractNum}}
                                    </td>
                                    <td style="padding: 10px;width: 120px">合同类型：</td>
                                    <td style="width: 300px">
                                        {{contractForm.contractType == 0?'销售合同':contractForm.contractType == 1?'技术服务合同':contractForm.contractType == 2?'部件买卖合同':contractForm.contractType == 2?'售后合同':'未知合同'}}
                                    </td>
                                </tr>
                                <tr>
                                    <td style="padding: 10px;width: 120px">
                                        公司类型：
                                    </td>
                                    <td style="width: 300px;">
                                        {{contractForm.companyType == 0?"建机厂":(contractForm.companyType ==
                                        1?"国企":(contractForm.companyType == 2?"租赁商":(contractForm.companyType == 3?"代理商":"集团公司")))}}
                                    </td>
                                    <td style="padding: 10px;width: 120px">塔机数量：</td>
                                    <td style="width: 170px">
                                        {{contractForm.towerCount}}
                                    </td>
                                    <td style="padding: 10px;width: 120px">升降机数量：</td>
                                    <td style="width: 300px">
                                        {{contractForm.lifterCount}}
                                    </td>
                                </tr>
                                <tr>
                                    <td style="padding: 10px;width: 120px">
                                        应收账款(元)：
                                    </td>
                                    <td style="width: 300px;">
                                        {{contractForm.contractReceivables ==
                                        0?0:(contractForm.contractReceivables/100).toFixed(2)}}
                                    </td>
                                    <td style="padding: 10px;width: 120px">
                                        项目名称：
                                    </td>
                                    <td style="width: 300px;">
                                        {{contractForm.projectName}}
                                    </td>
                                    <td style="padding: 10px;width: 120px">
                                        服务周期(月)：
                                    </td>
                                    <td style="width: 300px;">
                                        {{contractForm.startDate}}~{{contractForm.endDate}}
                                    </td>
                                </tr>
                                <tr>
                                    <td style="padding: 10px;width: 120px">
                                        收费政策：
                                    </td>
                                    <td style="width: 300px;" colspan="5">
                                        {{contractForm.chargingPolicy}}
                                    </td>
                                </tr>
                                <tr>
                                    <td style="padding: 10px;width: 120px">
                                        特殊政策及发货说明：
                                    </td>
                                    <td style="width: 300px;" colspan="5">
                                        {{contractForm.specialPolicy}}
                                    </td>
                                </tr>
                                <tr>
                                    <td style="width: 92px">功能需求：</td>
                                    <td colspan="5">
                                        <div class="td-body">
                                            <table cellpadding="0" cellspacing="0" style="width: 100%">
                                                <tr>
                                                    <td style="border: 1px solid #EBEEF8;padding: 3px; text-align: center">
                                                        序号
                                                    </td>
                                                    <td :key="i" v-for="(item,i) in towerItems"
                                                        style="border: 1px solid #EBEEF8;padding: 3px;text-align: center">
                                                        {{item.index}}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style="border: 1px solid #EBEEF8;text-overflow:ellipsis;padding: 3px; text-align: center">
                                                        塔机
                                                    </td>
                                                    <td :key="i" v-for="(item,i) in towerItems"
                                                        style="border: 1px solid #EBEEF8;padding: 3px;text-align: center">
                                                        {{item.text}}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style="border: 1px solid #EBEEF8;padding: 3px;text-align: center">
                                                        数量
                                                    </td>
                                                    <td :key="i" v-for="(item,i) in towerItems"
                                                        style="text-align: center;border: 1px solid #EBEEF8;padding: 3px;">
                                                        {{item.quality}}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style="border: 1px solid #EBEEF8;padding: 3px; text-align: center">
                                                        升降机
                                                    </td>
                                                    <td :key="i" v-for="(item,i) in lifterItems"
                                                        style="border: 1px solid #EBEEF8;padding: 3px;text-align: center">
                                                        {{item.text}}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style="border: 1px solid #EBEEF8;padding: 3px;text-align: center">
                                                        数量
                                                    </td>
                                                    <td :key="i" v-for="(item,i) in lifterItems"
                                                        style="text-align: center;border: 1px solid #EBEEF8;padding: 3px;">
                                                        {{item.quality}}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style="border: 1px solid #EBEEF8;padding: 3px; text-align: center">
                                                        特殊型号
                                                    </td>
                                                    <td :key="i" v-for="(item,i) in specialItems"
                                                        style="border: 1px solid #EBEEF8;padding: 3px;text-align: center">
                                                        {{item.text}}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style="border: 1px solid #EBEEF8;padding: 3px;text-align: center">
                                                        数量
                                                    </td>
                                                    <td :key="i" v-for="(item,i) in specialItems"
                                                        style="text-align: center;border: 1px solid #EBEEF8;padding: 3px;">
                                                        {{item.quality}}
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </td>
                                </tr>
                            </table>
                            <template>
                                <el-table
                                        :data="basicTable"
                                        style="width: 100%"
                                        :header-cell-style="{ background: '#EBEEF8',height: '50px', 'text-align':'center' }"
                                        :cell-style="{padding:'0',height: '41px'}">
                                    <el-table-column type="index" label="序号" width="50px" align="center"></el-table-column>
                                    <el-table-column prop="deviceCategory" label="设备类型" align="center">
                                        <template slot-scope="scope">
                                            <span v-if="scope.row.deviceCategory == 1">升降机</span>
                                            <span v-else>塔机</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="sizeModel" label="规格型号" align="center"></el-table-column>
                                    <el-table-column prop="siteNum" label="现场编码" align="center"></el-table-column>
                                    <el-table-column prop="stringDiameter" label="钢丝绳直径(mm)" align="center"></el-table-column>
                                    <el-table-column prop="armLength" label="前臂长度(m)" align="center"></el-table-column>
                                    <el-table-column prop="maxLoad" label="最大载重(T)" align="center"></el-table-column>
                                    <el-table-column prop="deviceType" label="类型" align="center"></el-table-column>
                                    <el-table-column prop="isNeedCard" label="是否需要视频流量卡" align="center">
                                        <template slot-scope="scope">
                                            <span v-if="scope.row.isNeedCard == 1">是</span>
                                            <span v-else>否</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="isNeedModel" align="center" label="是否需要现场调试多塔防碰撞功能">
                                        <template slot-scope="scope">
                                            <span v-if="scope.row.isNeedModel == 1">是</span>
                                            <span v-else>否</span>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </template>
                        </div>
                    </div>
                    <div class="approval-table">
                        <div class="text-title">
                            审批历史
                        </div>
                        <template>
                            <el-table
                                    :data="approvalRecord"
                                    border
                                    style="width: 100%">
                                <el-table-column prop="depName" label="部门"></el-table-column>
                                <el-table-column prop="userName" label="审批人"></el-table-column>
                                <el-table-column prop="auditRemark" label="意见">
                                    <template slot-scope="scope">
                                        <span v-if="scope.row.auditRemark == null">无</span>
                                        <span v-else>{{scope.row.auditRemark}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="时间" :formatter="dateFormat">
                                    <template slot-scope="scope">
                                        <span v-if="scope.row.createTime == null">无</span>
                                        <span v-else>{{scope.row.createTime}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="状态">
                                    <template slot-scope="scope">
                                        <span v-if="scope.row.isPass == 0 && scope.row.isEffective == 1"
                                              style="color: red">驳回</span>
                                        <span v-else-if="scope.row.isPass == 0 && scope.row.isEffective == 0"
                                              style="color: #F08080">驳回</span>
                                        <span v-else-if="scope.row.isPass == 1 && scope.row.isEffective == 1"
                                              style="color: green">通过</span>
                                        <span v-else-if="scope.row.isPass == 1 && scope.row.isEffective == 0"
                                              style="color: #98FB98">通过</span>
                                        <span v-else style="color: blue">未审批</span>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </template>
                    </div>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button type="primary" @click="infoVisible = false">确定</el-button>
                </span>
            </el-dialog>
        </div>
        <div class="export">
            <el-dialog
                    title="审批记录"
                    :visible.sync="exportDialogVisible"
                    :close-on-click-modal="false"
                    width="52.6%" center>
                <download-excel
                        class="export-excel-wrapper"
                        :data="json_data"
                        :fields="json_fields"
                        :name="fileName">
                    <el-table
                            :data="tableData"
                            max-height="400px"
                            size="small"
                            row-class-name="row"
                            cell-class-name="column"
                            :highlight-current-row="true"
                            fit>
                        <el-table-column
                                v-for="(item, index) in jsonFields"
                                :key="index"
                                :prop="item.prop"
                                :width="item.width"
                                :label="item.label" show-overflow-tooltip>
                        </el-table-column>
                    </el-table>
                    <!-- 上面可以自定义自己的样式，还可以引用其他组件button -->
                    <el-button type="primary" size="small" style="margin-left: 48%;margin-top: 1%"
                               @click="exportDialogVisible=false">导出数据
                    </el-button>
                </download-excel>
            </el-dialog>
        </div>
    </div>
</template>

<script>
    import contract from '../../../public/json/contract.json'
    import shipping from '../../../public/json/shipping.json'
    import funOptions from '../../../public/json/funOptions.json'
    import approval from '../../../public/json/approval.json'
    import basicData from '../../../public/json/basicData.json'

    export default {
        data() {
            return {
                tableHeight: '',
                pageList: [14,20,30,50],
                jsonFields: [
                    /* {label: '', width: '40', prop: 'id'},*/
                    {label: '合同编号', width: '120', prop: 'number'},
                    {label: '合同类型', width: '120', prop: 'contractType'},
                    {label: '销售人员', width: '120', prop: 'salesman'},
                    {label: '应收账款(元)', width: '120', prop: 'amount'},
                    {label: '客户名称', width: '120', prop: 'companyName'},
                    {label: '公司类型', width: '120', prop: 'unitType'},
                    {label: '当前状态', width: '120', prop: 'approvalStatus'},
                    {label: '审批意见', width: '120', prop: 'remark'},
                ],
                fileName: '',
                lineHeight: '',
                fields: [],
                towerItems: [],
                lifterItems: [],
                specialItems: [],
                values: [],
                json_fields: {},
                json_data: [],
                json_meta: [
                    [
                        {
                            " key ": " charset ",
                            " value ": " utf- 8 "
                        }
                    ]
                ],
                basicTable: [],
                approvalRecord: [],
                checkAll: false,
                exportDialogVisible: false,
                functions: [],
                funNameList: [],
                isIndeterminate: true,
                dialogVisibles: false,
                shippingVisible: false,
                infoVisible: false,
                multipleSelection: [],
                saleList: [],
                delList: [],
                // totalPage:0,
                searchItem: {
                    contractNum: '',
                    companyName: '',
                    userName: '',
                    userTel: '',
                    contractType: null,
                    companyType: null,
                    startDate: '',
                    endDate: '',
                    pageSize: 14,
                    pageIndex: 1
                },
                totalPage: 30,
                dialogVisible: false,
                option: {
                    pageIndex: '',
                    pageSize: 14,
                    currentPage: 2
                },
                contractTypes: [
                    {
                        label: '售后合同',
                        value: 3
                    },
                    {
                        label: '部件买卖合同',
                        value: 2
                    },
                    {
                        label: '技术服务合同',
                        value: 1
                    },
                    {
                        label: '销售合同',
                        value: 0
                    }
                ],
                unitTypes: [
                    {
                        label: '建机厂',
                        value: 0
                    },
                    {
                        label: '国企',
                        value: 1
                    },
                    {
                        label: '租赁商',
                        value: 2
                    },
                    {
                        label: '代理商',
                        value: 3
                    },
                    {
                        label: '集团公司',
                        value: 4
                    },
                ],
                UpDialogVisible: false,
                tableData: [],
                showTitle: '',
                shippingData: [],
                contractForm: {
                    companyName: '',
                    contractNum: '',
                    contractType: '',
                    companyType: '',
                    towerCount: '',
                    lifterCount: '',
                    projectName: '',
                    endDate: '',
                    startDate: '',
                    contractReceivables: '',
                    chargePolicy: '',
                    specialPolicy: '',
                    auditRemark: ''
                }
            }
        },
        methods: {

            saveContract(formName) {
                this.$refs[formName].validate(item => {
                    if (item) {
                        
                    } else {
                        this.$message.error("输入的数据有误")
                    }
                })
            },
            pageChange(option) {
                this.option.pageIndex = option;
                this.searchItem.pageIndex = option;
                this.$api.pageAuditRecord(this.searchItem).then(res => {
                    if (res.code == 200) {
                        this.tableData = res.data.content;
                        this.totalPage = res.data.totalElements;
                    }
                })
            },
            pageSizeChange(val){
                this.searchItem.pageSize = val;
                this.$api.pageAuditRecord(this.searchItem).then(res => {
                    if (res.code == 200) {
                        this.tableData = res.data.content;
                        this.totalPage = res.data.totalElements;
                    }
                })
            },
            exportExcel() {
                this.exportDialogVisible = true;
                this.fileName = "审批记录";
            },
            closeDialog(val) {
                this.exportDialogVisible = !this.exportDialogVisible;
            },
            searchBtn() {
                this.pageChange(1)
            },
            showContract(id) {
                this.infoVisible = true;
                this.towerItems = [];
                this.lifterItems = [];
                this.$api.getAuditRecordById({id}).then(res => {
                    if (res.code == 200) {
                        this.contractForm = {
                            companyName: res.data.companyName,
                            contractNum: res.data.contractNum,
                            contractType: res.data.contractType,
                            companyType: res.data.companyType,
                            towerCount: res.data.towerCount,
                            endDate: res.data.endDate,
                            startDate: res.data.startDate,
                            lifterCount: res.data.lifterCount,
                            projectName: res.data.projectName,
                            contractReceivables: res.data.contractReceivables,
                            chargePolicy: res.data.chargePolicy,
                            specialPolicy: res.data.specialPolicy,
                            auditRemark: res.data.auditRemark
                        }
                        this.basicTable = res.data.deviceList;
                        this.approvalRecord = res.data.auditList;
                        if (res.data.applyType == 0) {
                            this.showTitle = "《" + res.data.contractNum + "》" + '合同申请详情'
                        } else {
                            this.showTitle = "《" + res.data.contractNum + "》" + '协议申请详情'
                        }
                        var towerItemsStr = res.data.towerMap;
                        if (towerItemsStr) {
                            var funcItem = {};
                            var index = 1
                            for (var i in towerItemsStr) {
                                funcItem = {text: '', quality: 0}
                                funcItem.text = i;
                                funcItem.index = index;
                                funcItem.quality = towerItemsStr[i];
                                this.towerItems.push(funcItem);
                                index = index + 1
                            }
                        }
                        var lifterItemsStr = res.data.lifterMap;
                        if (lifterItemsStr) {
                            var funcItem = {};
                            for (var i in lifterItemsStr) {
                                funcItem = {text: '', quality: 0}
                                funcItem.text = i;
                                funcItem.quality = lifterItemsStr[i];
                                this.lifterItems.push(funcItem);
                            }
                        }
                        var specialItemsStr = res.data.specialMap;
                        if (specialItemsStr) {
                            var funcItem = {};
                            for (var i in specialItemsStr) {
                                funcItem = {text: '', quality: 0}
                                funcItem.text = i;
                                funcItem.quality = specialItemsStr[i];
                                this.specialItems.push(funcItem);
                            }
                        }
                    }
                })
            },
            handleCheckAllChange(val) {
                this.contractForm.demand = val ? this.functions : [];
                this.isIndeterminate = false;
            },
            handleCheckedFunctionsChange(value) {
                let checkedCount = value.length;
                this.checkAll = checkedCount === this.functions.length;
                this.isIndeterminate = checkedCount > 0 && checkedCount < this.functions.length;
            },
            dateFormat(row, column) {
                let data = row[column.property]
                let dt = new Date(data)
                var year = dt.getFullYear();
                var month = (dt.getMonth() + 1) < 10 ? 0 + '' + (dt.getMonth() + 1) : ((dt.getMonth() + 1))
                var date = dt.getDate() < 10 ? 0 + '' + dt.getDate() : (dt.getDate())
                return year + '-' + month + '-' + date
            },
            resetBtn() {
                this.searchItem = {
                    contractNum: '',
                    companyName: '',
                    userName: '',
                    userTel: '',
                    contractType: null,
                    companyType: null,
                    projectName: null,
                    startDate: '',
                    endDate: '',
                    pageSize: 14,
                    pageIndex: 1
                }
                this.pageChange(1);
            }
        },
        created() {
            this.searchBtn();
            this.$api.getSaleList().then(res => {
                if (res.code == 200){
                    this.saleList = res.data
                }
            })
            this.tableHeight = (window.innerHeight)*0.67
            if (window.screen.height > 900) {
                this.lineHeight = window.screen.height * 0.038 + 'px';
            } else {
                this.lineHeight = window.screen.height * 0.033 + 'px';
            }
        }
    }
</script>

<style lang="scss" scoped>
    .iconfont {
        font-family: "iconfont" !important;
        font-size: 24px;
        font-style: normal;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        margin-top: 3px;
        display: block;
        cursor: pointer;
    }

    .page-body {
        background-color: #FFFFFF;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .table-body {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    .table-header {
        height: 18%;
        margin-top: 0.5%;
        background-color: #FFFFFF;
    }

    .handle-box {
        padding: 5px;
        margin-left: 10px;
    }

    .table-info {
        height: 94%;
        margin-top: 5px;
        background-color: #FFFFFF;
        display: flex;
        flex-direction: column;
    }

    .table-button {
        flex: 1;
        margin: 0 1%;
    }

    .table-data {
        height: 6%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .text-title {
        font-size: 16px;
        font-weight: bold;
        color: #437EE6;
        text-align: center;
        margin-bottom: 20px;
    }

    .dialog-body {
        max-height: 650px;
        overflow-y: scroll;
    }

    .handle-input {

        display: inline-block;
    }

    .handle-input-date {
        width: 150px;
        display: inline-block;
        padding-right: 0px;
    }

    .information {
        background-color: #F0F2F5;
    }

    .dialog-title {
        margin: 0 20px;
        color: #67ADD9;
        font-size: 20px;
        /*border: 1px red solid;*/
    }

    .payment-table, .approval-table {
        margin-top: 10px;
    }

    .basic-info .el-button {
        background: linear-gradient(to bottom, #39AACE, #E1E3E3);
        border: 1px blue solid;
    }

    .payment-table .el-button {
        border: 1px green solid;
        background: linear-gradient(to bottom, #39AACE, #E1E3E3);
    }

    .approval-table .el-button {
        border: 1px black solid;
        background: linear-gradient(to bottom, #39AACE, #E1E3E3);
    }

    .file-info {
        border: 1px yellow solid;
    }

    .text-title {
        font-size: 16px;
        font-weight: bold;
        color: #437EE6;
        text-align: center;
        margin-bottom: 20px;
    }

    .dialog-body {
        max-height: 650px;
        overflow-y: scroll;
    }

    .td-body {
        padding: 10px 0;
        white-space: nowrap;
        overflow-x: scroll;
        width: 1100px;
    }

    td {
        white-space: nowrap; /*控制单行显示*/
        overflow: hidden; /*超出隐藏*/
        text-overflow: ellipsis; /*隐藏的字符用省略号表示*/
    }

    .td-text {
        width: 90px;
        text-align: right;
    }

    .td-input {
        width: 28%;
    }
</style>
